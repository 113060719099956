import {Component, OnInit} from '@angular/core';
import {User} from '../../_shared/models/User';
import {catchError, finalize} from 'rxjs/operators';
import {UserService} from '../user.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {UserEditorComponent} from '../user-editor/user-editor.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MatTableDataSource} from '@angular/material/table';
import {WorkLog} from '../../_shared/models/WorkLog';
import {GlobalEventsService} from '../../_shared/services/global-events.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  public isLoading = 0;
  public user: User;
  public userId;
  public columnsToDisplay = ['date', 'hours', 'description', 'actions'];
  public dataSource: MatTableDataSource<WorkLog>;
  public authUser: User;

  constructor(public globalEventsService: GlobalEventsService,
              public userService: UserService,
              private route: ActivatedRoute,
              public toastrService: ToastrService,
              public dialog: MatDialog,
              private router: Router) {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.fetchUser();
  }

  ngOnInit(): void {
    this.authUser = this.globalEventsService.getAuthUser();
  }

  private fetchUser() {
    this.isLoading++;
    this.userService.show(this.userId)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.user = res.data;

        },
        err => console.log(err)
      );
  }

  public editUser(row: any) {
    const dialogRef = this.dialog.open(UserEditorComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '40%' : '90%',
      data: {
        user: row,
        editorType: 'edit'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.userService.update(row.id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully edited!');
              this.fetchUser();
            },
            err => console.log(err)
          );
      }
    });
  }
}
