import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../models/User';

@Injectable()
export class GlobalEventsService {
  private authUserSource     = new BehaviorSubject<User>(null);
  private authStatusSource   = new BehaviorSubject<boolean>(null);

  public authUser$     = this.authUserSource.asObservable();
  public authStatus$   = this.authStatusSource.asObservable();

  public authUser: User;

  setAuthUser(user: User) {
    this.authUser = user;
    this.authUserSource.next(user);
    this.authStatusSource.next(!!user);
  }

  pushError(errorResponse) {
    // this.globalErrorsSource.next(errorResponse);
  }

  public getAuthUser(){
    return this.authUser;
  }
}
