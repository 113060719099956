
<div *ngIf="isLoading || !formActive" class="row my-4">
  <div class="col-12">
    <mat-spinner style="margin: auto"></mat-spinner>
  </div>
</div>

<form *ngIf="!isLoading && formActive" [formGroup]="form">

  <div class="row">
    <div class="col-12 col-sm-6 mb-3">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h5>Profile settings</h5>
          </div>
        </div>
        <div class="card-body">

          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput placeholder="Ex. 8" formControlName="name">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Preferred working hours per day</mat-label>
            <input matInput placeholder="Ex. 8" formControlName="pref_hours">
          </mat-form-field>

        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h5>Account settings</h5>
          </div>
        </div>
        <div class="card-body">
          <mat-form-field>
            <mat-label>Current password</mat-label>
            <input type="password" matInput placeholder="Leave empty if not changing" formControlName="password">
          </mat-form-field>

          <mat-form-field>
            <mat-label>New Password</mat-label>
            <input type="password" matInput placeholder="Leave empty if not changing" formControlName="new_password">
          </mat-form-field>

          <mat-form-field>
            <mat-label>New Password Confirmation</mat-label>
            <input type="password" matInput placeholder="Leave empty if not changing" formControlName="new_password_confirmation">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 text-center">
      <button class="btn btn-primary" (click)="submitForm()">Save</button>
    </div>
  </div>
</form>
