import { Component, OnInit } from '@angular/core';
import {GlobalEventsService} from '../../_shared/services/global-events.service';
import {User} from '../../_shared/models/User';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector:    'app-nav',
  templateUrl: './nav.component.html',
  styleUrls:   ['./nav.component.css']
})
export class NavComponent implements OnInit {
  public authUser: User;

  constructor(private globalEventsService: GlobalEventsService,
              private authService: AuthService,
              private router: Router,
  ) { }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(
      user => this.authUser = user
    );


  }

  logout(){
    this.authService.logout()
      .subscribe(res => {
        this.globalEventsService.setAuthUser(null);
        this.router.navigateByUrl('/login');
      });
  }
}
