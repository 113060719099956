import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GlobalEventsService} from '../_shared/services/global-events.service';
import {User} from '../_shared/models/User';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../auth/auth.service';
import {finalize} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {WorkLog} from '../_shared/models/WorkLog';
import {Router} from '@angular/router';

@Component({
  selector:    'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  public authUser: User;
  public form: FormGroup;
  public isLoading  = 0;
  public formActive = false;

  constructor(public fb: FormBuilder,
              public toastrService: ToastrService,
              public authService: AuthService,
              public router: Router,
              private globalEventsService: GlobalEventsService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(
      user => {
        this.authUser = user;
        if (this.authUser) {
          this.buildForm();
        }
      }
    );
  }

  private buildForm() {
    this.form = this.fb.group({
      name:                      [this.authUser.name, [Validators.required]],
      pref_hours:                [this.authUser.pref_hours, [Validators.required]],
      password:                  [null, []],
      new_password:              [null, []],
      new_password_confirmation: [null, []],
    });

    this.formActive = true;
  }

  public submitForm() {
    if (this.form.invalid) {
      this.toastrService.error('Please fill all fields!');
      return;
    }

    const formData = this.form.value;

    if (formData.new_password) {
      if (formData.new_password !== formData.new_password_confirmation) {
        this.toastrService.error('New password and confirmation do not match!');
        return;
      }

      if (!formData.password || formData.password === '') {
        this.toastrService.error('Please fill current password!');
        return;
      }
    }

    if (formData.password === null || formData.password === '') {
      delete formData.password;
    }

    if (formData.new_password === null || formData.new_password === '') {
      delete formData.password;
      delete formData.new_password;
      delete formData.new_password_confirmation;
    }

    this.isLoading++;
    this.authService.updateUser(formData)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.toastrService.success('Successfully updated!');
          this.router.navigate(['/dashboard']);
        },
        err => console.log(err)
      );
  }
}
