import { Injectable } from '@angular/core';
import { BaseApiService } from '../../_shared/services/base-api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResponse } from '../../_shared/contracts/laravel-response.interface';

@Injectable({
  providedIn: 'root'
})
export class WorkHistoryService extends BaseApiService {

  public index(userId, filter = {}): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(this.apiUrl + '/user/' + userId + '/work-log', {params: filter}).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public indexProjectWorkLog( filter = {}): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(this.apiUrl + '/work-log', {params: filter}).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public store(userId, data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(this.apiUrl + '/user/' + userId + '/work-log', data).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public update(id: any, data: any) {
    return this.http.put<LaravelResponse>(this.apiUrl + '/work-log/' + id, data).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public delete(id: number) {
    return this.http.delete<LaravelResponse>(this.apiUrl + '/work-log/' + id).pipe(
      catchError(response => this.handleError(response)),
    );
  }
}
