<div *ngIf="isLoading" class="row">
  <div class="col-12">
    <mat-spinner style="margin: auto"></mat-spinner>
  </div>
</div>

<ng-container *ngIf="!isLoading">
  <div class="card">
    <div class="card-header">
      <div class="card-title text-center mb-0">
        <h5 class="mb-0">{{ user.name }} Profile</h5>
      </div>
    </div>

    <div class="card-body">
      <button class="btn btn-sm btn-warning m-1 float-right" (click)="editUser(user)">Edit user</button>

      <p class="pt-5 p-sm-0">Name: {{ user.name }}</p>
      <p>Email: {{ user.email }}</p>
      <p>Preferred hours: {{ user.pref_hours || 'Not set' }}</p>
    </div>
  </div>

  <div class="mt-3">
  </div>

</ng-container>


