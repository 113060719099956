import {Component, OnInit} from '@angular/core';
import {GlobalEventsService} from '../_shared/services/global-events.service';
import {FormBuilder} from '@angular/forms';
import {User} from '../_shared/models/User';
import {filter, finalize} from 'rxjs/operators';
import {ProjectService} from '../project/project.service';
import {Project} from '../_shared/models/Project';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  public authUser: User;
  public projects: Project[] = [];
  public isLoading = 0;
  public isLoadingProjectWorkLog = 0;
  public userWorkLogComponent = true;
  public allProjectsWorkLogComponent = false;
  public singleProjectWorkLogComponent = false;
  public chosenProject;

  constructor(private globalEventsService: GlobalEventsService,
              private fb: FormBuilder,
              private projectService: ProjectService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$
      .pipe(
        filter(user => !!user)
      )
      .subscribe(
      user => {
        this.authUser = user;
      }
    );
    this.fetchProjects();
  }

  private fetchProjects() {
    this.isLoading++;
    this.projectService.index({all: true})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          if (this.authUser.role_id === 2) {
            this.projects = res.data.manager;
          } else {
            this.projects = res.data;
          }
        },
        err => console.log(err)
      );
  }

  public loadComponent(type: string, project?) {
    if (type === 'user') {
      this.userWorkLogComponent = true;
      this.allProjectsWorkLogComponent = false;
      this.singleProjectWorkLogComponent = false;
    } else if (type === 'All data') {
      this.userWorkLogComponent = false;
      this.allProjectsWorkLogComponent = true;
      this.singleProjectWorkLogComponent = false;
    } else {
      this.userWorkLogComponent = false;
      this.allProjectsWorkLogComponent = false;
      this.singleProjectWorkLogComponent = false;
      setTimeout(() => {
        this.singleProjectWorkLogComponent = true;
        this.isLoadingProjectWorkLog++;
      }, 1000);
      this.isLoadingProjectWorkLog--;
      this.chosenProject = project;
    }
  }
}
