import {Component, OnInit} from '@angular/core';
import {User} from './_shared/models/User';
import {AuthService} from './auth/auth.service';
import {GlobalEventsService} from './_shared/services/global-events.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'WEB';
  public authUser: User;
  public isLoading = 0;

  constructor(public authService: AuthService,
              public globalEventsService: GlobalEventsService) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.authService.checkLogin()
      .pipe(finalize(() => this.isLoading--))
      .subscribe();  // Init auth user check
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }
}
