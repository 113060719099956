<div class="card">
  <div class="card-header">
    <div class="card-title text-center mb-0">
      <h5 class="mb-0">{{ editorType === 'create' ? 'Add new record' : 'Update record' }}</h5>
    </div>
  </div>

  <div class="card-body">

    <div *ngIf="isLoading" class="row">
      <div class="col-12">
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>

    <form *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select formControlName="role_id" placeholder="Select role">
              <mat-option *ngIf="authUser.role_id === 1" [value]="1">Admin</mat-option>
              <mat-option [value]="2">Manager</mat-option>
              <mat-option [value]="3">User</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="text" matInput placeholder="User full name" formControlName="name">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input type="text" matInput placeholder="user email" formControlName="email">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Preferred hours</mat-label>
            <input type="number" rows="4" matInput placeholder="Preferred hours" formControlName="pref_hours">
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 text-right">
        <button type="button" class="btn btn-danger mr-2" (click)="onCloseClick()">Close</button>
        <button type="submit" class="btn btn-primary">{{ editorType === 'create' ? 'Create' : 'Update' }}</button>
      </div>
    </form>

  </div>
</div>
