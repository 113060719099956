import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {User} from '../../_shared/models/User';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../user.service';
import {finalize} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {UserEditorComponent} from '../user-editor/user-editor.component';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {GlobalEventsService} from '../../_shared/services/global-events.service';

@Component({
  selector:    'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
  public isLoading        = 0;
  public authUser: User;
  public columnsToDisplay = ['name', 'email', 'role', 'actions'];
  public dataSource: MatTableDataSource<User>;
  public users: Array<User>;

  public paginatorConfig = {
    pageIndex: 0,
    pageSize:  20,
    length:    1,
  };

  constructor(public toastrService: ToastrService,
              public userService: UserService,
              private globalEventsService: GlobalEventsService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(
      user => {
        this.authUser = user;
      }
    );

    this.fetchUsers();
  }

  private fetchUsers() {
    this.dataSource = new MatTableDataSource<User>([]);
    this.isLoading++;

    const data: any = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1
    };

    this.userService.index(data)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.users                  = res.data.data;
          this.dataSource             = new MatTableDataSource<User>(this.users);
          this.paginatorConfig.length = res.data.total;
        },
        err => console.log(err)
      );
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchUsers();
  }

  public addUser() {
    const dialogRef = this.dialog.open(UserEditorComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '40%' : '90%',
      data:  {
        user: new User(),
        editorType: 'create'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.userService.store(result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully added!');
              this.fetchUsers();
            },
            err => {
              this.toastrService.error(err.error.data);
            }
          );
      }
    });
  }

  public editUser(row: any) {
    const dialogRef = this.dialog.open(UserEditorComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '40%' : '90%',
      data:  {
        user: row,
        editorType: 'edit'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.userService.update(row.id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully edited!');
              this.fetchUsers();
            },
            err => console.log(err)
          );
      }
    });
  }

  public deleteUser(row: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '30%' : '90%',
      data:  row
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isLoading++;
        this.userService.delete(row.id)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully deleted!');
              this.fetchUsers();
            },
            err => console.log(err)
          );
      }
    });
  }

}
