import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {SharedModule} from '../_shared/shared.module';



@NgModule({
  declarations: [SettingsComponent],
  imports: [
    SharedModule,

    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
  ],
  providers: []
})
export class SettingsModule { }
