import { Injectable } from '@angular/core';
import { BaseApiService } from '../_shared/services/base-api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResponse } from '../_shared/contracts/laravel-response.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {

  public index(filter = {}): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(this.apiUrl + '/user', {params: filter}).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public store(data): Observable<LaravelResponse> {
    return this.http.post<LaravelResponse>(this.apiUrl + '/user', data).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public show(id: any): Observable<LaravelResponse> {
    return this.http.get<LaravelResponse>(this.apiUrl + '/user/' + id).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public update(id: any, data: any) {
    return this.http.put<LaravelResponse>(this.apiUrl + '/user/' + id, data).pipe(
      catchError(response => this.handleError(response)),
    );
  }

  public delete(id: number) {
    return this.http.delete<LaravelResponse>(this.apiUrl + '/user/' + id).pipe(
      catchError(response => this.handleError(response)),
    );
  }


}
