<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="col-12 col-sm-12 col-lg-6 mb-2">
        <h5>Projects <small class="float-right">Total: {{ totalWorkHours }} Hrs</small></h5>
        <hr/>
        <div *ngFor="let project of projectsData | keyvalue">
          {{ project.value.name }} : {{ project.value.hours }} Hrs
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-6 ml-auto float-right d-flex justify-content-between align-items-start">
        <div *ngIf="isLoading || isLoadingProjects" class="row my-4">
          <div>
            <mat-spinner style="margin: auto"></mat-spinner>
          </div>
        </div>
        <button class="btn btn-secondary mr-2 ml-auto float-right" (click)="exportTable()">Export Data</button>
      </div>
    </div>
  </div>
  <div class="card-body" id="calendarDiv">
    <full-calendar #cal *ngIf="calendarOptions"
                   [options]="calendarOptions" class=""></full-calendar>
  </div>
</div>
