import {Component, OnInit} from '@angular/core';
import {User} from '../../_shared/models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LaravelResponse} from '../../_shared/contracts/laravel-response.interface';
import {AuthService} from '../auth.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector:    'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public user: User;
  public loginForm: FormGroup;
  public serverResponse: LaravelResponse;
  public isLoading           = 0;

  constructor(public authService: AuthService,
              private routerService: Router,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.getCSRFToken();
    this.buildForm();
  }

  private getCSRFToken() {
    this.authService.getCSRFCookie()
      .subscribe(
        r => { },
        e => console.error(e)
      );
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  buildForm() {
    this.loginForm = this.fb.group({
      email:    [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  public login(form) {
    this.isLoading++;
    this.authService.login(form.value)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          // const intendedUrl = this.authService.redirectUrl ? this.authService.redirectUrl : '/home/dashboard';
          this.routerService.navigate(['/dashboard']);
        },
        error => {
          console.log(error);
          this.serverResponse = error;
        },
      );
  }
}
