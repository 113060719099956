import {AfterContentInit, AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {Project} from '../../_shared/models/Project';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import {UserService} from '../../user/user.service';
import {User} from '../../_shared/models/User';
import {Input} from '../../_shared/interfaces/Input';
import {zip} from 'rxjs';

@Component({
  selector: 'app-project-populate',
  templateUrl: './project-populate.component.html',
  styleUrls: ['./project-populate.component.scss']
})
export class ProjectPopulateComponent implements OnInit {
  public isLoading = 0;
  public project: Project;
  public form: FormGroup;
  public users: User[] = [];
  public oldWorkers: User[] = [];
  public record: any;
  public allSelected = false;
  public input: Input = {
    name: 'All',
    selected: false,
    color: 'primary',
    workers: []
  };

  constructor(private toastrService: ToastrService,
              private userService: UserService,
              public fb: FormBuilder,
              public dialogRef: MatDialogRef<ProjectPopulateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.record = data.project;
  }

  ngOnInit() {
    this.populateForm();

    const observableOne$ = this.userService.index({all: true});
    const observableTwo$ = this.userService.index({project_id: this.record.id});
    const observableFinal$ = zip(observableOne$, observableTwo$);
    this.isLoading++;
    observableFinal$
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
      this.users = res[0].data;
      this.oldWorkers = res[1].data;
      this.populateInputWorkers();
    });
  }

  populateForm() {
    this.form = this.fb.group({
      project_id: [this.record ? this.record.id : null, []],
      project_name: [this.record ? this.record.name : null, []]
    });
  }

  populateInputWorkers() {
    this.users.map(user => {
      if (user.id !== this.record.manager_id) {
        this.input.workers.push({id: user.id, name: user.name, selected: this.oldWorkers.some(worker => worker.id === user.id), color: 'primary'});
      }
    });
    let tmpSelectAll = true;
    this.input.workers.map(single => {
      tmpSelectAll = tmpSelectAll && single.selected;
    });
    tmpSelectAll ? this.allSelected = true : this.allSelected = false;
  }

  someSelected(): boolean {
    if (this.input.workers == null) {
      return false;
    }
    return this.input.workers.filter(worker => worker.selected).length > 0 && !this.allSelected;
  }

  setAll(selected: boolean) {
    this.allSelected = selected;
    if (this.input.workers == null) {
      return;
    }
    this.input.workers.forEach(worker => worker.selected = selected);
  }

  updateAllComplete() {
    this.allSelected = this.input.workers != null && this.input.workers.every(worker => worker.selected);
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  submitForm() {
    let newWorkers = [];
    this.input.workers.map(worker => {
      if (worker.selected === true) {
        newWorkers.push(worker);
      }
    });
    this.dialogRef.close(newWorkers);
  }
}
