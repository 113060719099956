import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {SharedModule} from '../_shared/shared.module';
import {WorkHistoryModule} from './work-history/work-history.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {QuillModule} from 'ngx-quill';
import {MatPaginatorModule} from '@angular/material/paginator';
import { AllWorkHistoryComponent } from './all-work-history/all-work-history.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ProjectWorkHistoryComponent } from './project-work-history/project-work-history.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {ScrollingModule} from '@angular/cdk/scrolling';


@NgModule({
  declarations: [
    DashboardComponent,
    ConfirmDialogComponent,
    AllWorkHistoryComponent,
    ProjectWorkHistoryComponent
  ],
  imports: [
    SharedModule,
    WorkHistoryModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    QuillModule,
    MatPaginatorModule,
    MatTabsModule,
    FullCalendarModule,
    ScrollingModule,
  ],
})
export class DashboardModule {
}
