import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './_shared/guards/auth.guard';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginComponent} from './auth/login/login.component';
import {SettingsComponent} from './settings/settings.component';
import {UserListComponent} from './user/user-list/user-list.component';
import {AdminGuard} from './_shared/guards/admin.guard';
import {UserDetailComponent} from './user/user-detail/user-detail.component';
import {GuestGuard} from './_shared/guards/guest.guard';
import {ProjectListComponent} from './project/project-list/project-list.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';


const routes: Routes = [
  // User routes
  { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
  { path: 'settings', canActivate: [AuthGuard], component: SettingsComponent },

  // Manager routes
  { path: 'users', canActivate: [AuthGuard, AdminGuard], component: UserListComponent, data: {allowManager: false}},
  { path: 'users/:id/edit', canActivate: [AuthGuard, AdminGuard], component: UserListComponent, data: {allowManager: false}},

  // Admin routes
  { path: 'users/:id', canActivate: [AuthGuard, AdminGuard], component: UserDetailComponent, data: {allowManager: true}},

  { path: 'projects', canActivate: [AuthGuard, AdminGuard], component: ProjectListComponent, data: {allowManager: true}},
  // { path: 'projects/:id/edit', canActivate: [AuthGuard, AdminGuard], component: ProjectEditorComponent, data: {allowManager: true}},

  // Auth routes
  { path: 'login', canActivate: [GuestGuard], component: LoginComponent },
  // { path: 'register', canActivate: [GuestGuard], component: RegisterComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},


  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [AuthGuard, AdminGuard, GuestGuard],
})
export class AppRoutingModule { }
