<div class="row">
  <div class="col-12">
    <h1 class="text-center">Welcome to TimeManagement platform</h1>
  </div>
  <div class="col-md-8 offset-md-2 pt-5">
    <div class="card">
      <div class="card-header bg-white">
        <h3>Create new account</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="submitForm()">

          <div class="form-group">
            <label for="name">Name</label>
            <input type="name" class="form-control" id="name" formControlName="name" placeholder="Enter name" autocomplete="off">
            <div *ngIf="this.form.get('name').touched && this.form.get('name').errors?.required" class="alert alert-danger">Please enter your name.</div>
          </div>

          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" class="form-control" id="email" formControlName="email" placeholder="Enter email" autocomplete="off">
            <div *ngIf="this.form.get('email').touched && this.form.get('email').errors?.required" class="alert alert-danger">Please enter your email.</div>
            <div *ngIf="this.form.get('email').touched && this.form.get('email').errors?.email" class="alert alert-danger">Not a valid email address.</div>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" formControlName="password" placeholder="Password">
            <div *ngIf="this.form.get('password').touched && this.form.get('password').errors?.required" class="alert alert-danger">Please enter password.</div>
          </div>

          <div class="form-group">
            <label for="password_confirmation">Password</label>
            <input type="password" class="form-control" id="password_confirmation" formControlName="password_confirmation" placeholder="Password">
            <div *ngIf="this.form.get('password_confirmation').touched && this.form.get('password_confirmation').errors?.required" class="alert alert-danger">Please confirm password.</div>
          </div>

          <button *ngIf="!isLoading" type="submit" class="btn btn-primary">Submit</button>
          <div *ngIf="isLoading" class="row my-4">
            <div class="col-12">
              <mat-spinner style="margin: auto"></mat-spinner>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
