import { Injectable } from '@angular/core';
import { BaseApiService } from '../_shared/services/base-api.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LaravelResponse } from '../_shared/contracts/laravel-response.interface';
import { User } from '../_shared/models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  public login(user: User): Observable<LaravelResponse<User>> {
    return this.http.post<LaravelResponse>(this.apiUrl + '/login', user).pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }

  public register(data): Observable<any> {
    return this.http.post<LaravelResponse>(this.apiUrl + '/register', data).pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }

  public checkLogin() {
    return this.http.get<LaravelResponse>(this.apiUrl + '/auth-user').pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }

  public logout(): Observable<LaravelResponse>{
    return this.http.post<LaravelResponse>(`${this.apiUrl}/logout`, {})
        .pipe(
            catchError(response => this.handleError(response))
        );
  }

  public updateUser(data: any): Observable<LaravelResponse<User>> {
    return this.http.post<LaravelResponse>(this.apiUrl + '/profile', data).pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }
}
