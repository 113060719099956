<div class="row">
  <div class="col-12">
    <h1 class="text-center">Welcome to TimeManagement platform</h1>
  </div>
  <div class="col-md-8 offset-md-2 pt-5">
    <div class="card">
      <div class="card-header bg-white">
        <h3>Log In</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" class="form-control" id="email" formControlName="email" placeholder="Enter email" autocomplete="off">
            <div *ngIf="email.touched && email.errors?.required" class="alert alert-danger">Please enter your email.</div>
            <div *ngIf="email.touched && email.errors?.email" class="alert alert-danger">Not a valid email address.</div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" formControlName="password" placeholder="Password">
            <div *ngIf="password.touched && password.errors?.required" class="alert alert-danger">Not a valid email address.</div>
          </div>
          <button *ngIf="!isLoading" type="submit" class="btn btn-primary">Submit</button>
        </form>

        <div *ngIf="isLoading" class="row my-4">
          <div class="col-12">
            <mat-spinner style="margin: auto"></mat-spinner>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
