<div class="card" id="card">
  <div class="card-header">
    <div class="card-title text-center mb-0">
      <h5 class="mb-0">Preview record</h5>
    </div>
  </div>

  <div class="card-body">

    <div *ngIf="isLoading" class="row">
      <div class="col-12">
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>

    <div *ngIf="!isLoading" [formGroup]="form">
      <div class="row">
        <div *ngIf="authUser.role_id <=  2" class="col-12">
          <mat-form-field>
            <mat-label>User</mat-label>
            <input matInput readonly formControlName="user" class="btn" [routerLink]="['/users/', user.id]"
                   (click)="onCloseClick()">
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
          <mat-form-field>
            <mat-label>Project</mat-label>
            <input matInput readonly formControlName="project_name">
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input matInput readonly formControlName="date">
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
          <mat-form-field>
            <mat-label>Hours</mat-label>
            <input matInput readonly formControlName="hours">
          </mat-form-field>
        </div>
        <div class="col-12" style="height: 100%; padding-bottom: 1.25em;">
          <mat-label>Description</mat-label>
          <quill-editor [readOnly]="true" [modules]="{toolbar: false}" #editor placeholder="Ex. Worked on XY project..."
                        formControlName="description"></quill-editor>
        </div>
        <div class="col-12 d-flex justify-content-between">
          <div>
            <button *ngIf="editorType === 'editPreview'" type="button" class="btn btn-danger" (click)="onDeleteClick()">
              Delete
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-dark mr-2" (click)="onCloseClick()">Close</button>
            <button *ngIf="editorType === 'editPreview'" type="button" class="btn btn-primary" (click)="onEditClick()">
              Edit
            </button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
