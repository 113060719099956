import {environment} from '../../../environments/environment';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {GlobalEventsService} from './global-events.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class BaseApiService {
  public apiUrl: string;
  public apiDomain: string;

  constructor(public router: Router,
              public globalEvents: GlobalEventsService,
              public toastrService: ToastrService,
              public http: HttpClient) {
    this.apiUrl    = environment.API_URL;
    this.apiDomain = environment.API_DOMAIN;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status === 401) {
        this.globalEvents.setAuthUser(null);
        this.router.navigateByUrl('/login');
      }

      if (error.status === 419) {
        this.getCSRFCookie().subscribe(r => {
          alert('Session expired, please try again');
        });
      }

      if (error.status === 422) {
        const title = error.error.message;
        let message = '';

        Object.keys(error.error.errors).map(key => {
          message += key + ': ';
          message += error.error.errors[key].join(',');
        });

        this.toastrService.error(message, title);
      }

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  public getCSRFCookie() {
    return this.http.get(this.apiDomain + '/sanctum/csrf-cookie')
      .pipe(
        catchError(response => this.handleError(response)),
      );
  }
}
