import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ToastrService} from 'ngx-toastr';
import {ProjectService} from '../project.service';
import {finalize} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ProjectEditorComponent} from '../project-editor/project-editor.component';
// import {ProjectPopulateComponent} from '../project-populate/project-populate.component';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {Project} from '../../_shared/models/Project';
import {GlobalEventsService} from '../../_shared/services/global-events.service';
import {ProjectPopulateComponent} from '../project-populate/project-populate.component';

@Component({
  selector:    'app-project-list',
  templateUrl: './project-list.component.html'
})
export class ProjectListComponent implements OnInit {
  public isLoading        = 0;
  public columnsToDisplay = ['name', 'description', 'client', 'note', 'manager', 'actions'];
  public dataSource: MatTableDataSource<Project>;
  public projects: Array<Project>;
  public authUser;

  public paginatorConfig = {
    pageIndex: 0,
    pageSize:  20,
    length:    1,
  };

  constructor(public toastrService: ToastrService,
              public projectService: ProjectService,
              public dialog: MatDialog,
              public globalEventsService: GlobalEventsService) {
  }

  ngOnInit(): void {
    this.fetchProjects();
    this.authUser = this.globalEventsService.getAuthUser();
  }

  private fetchProjects() {
    this.dataSource = new MatTableDataSource<Project>([]);
    this.isLoading++;

    const data: any = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1
    };

    this.projectService.index(data)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          if (this.authUser.role_id === 1) {
            this.projects                  = res.data.data;
          } else if (this.authUser.role_id === 2) {
            this.projects                  = res.data.manager.data;
          }
          this.dataSource             = new MatTableDataSource<Project>(this.projects);
          this.paginatorConfig.length = res.data.total;
        },
        err => console.log(err)
      );
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchProjects();
  }

  public addProject() {
    const dialogRef = this.dialog.open(ProjectEditorComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '40%' : '90%',
      data:  {
        editorType: 'create'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.projectService.store(result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully added!');
              this.fetchProjects();
            },
            err => {
              this.toastrService.error(err.error.data);
            }
          );
      }
    });
  }

  public addUserToProject(row: any) {
    const dialogRef = this.dialog.open(ProjectPopulateComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '40%' : '90%',
      data: {
        project: row,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.projectService.hireWorkers(row.id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully edited!');
              this.fetchProjects();
            },
            err => console.log(err)
          );
      }
    });
  }

  public editProject(row: any) {
    const dialogRef = this.dialog.open(ProjectEditorComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '40%' : '90%',
      data:  {
        project: row,
        editorType: 'edit'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.projectService.update(row.id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully edited!');
              this.fetchProjects();
            },
            err => console.log(err)
          );
      }
    });
  }

  public deleteProject(row: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: document.getElementById('card').offsetWidth > 768 ? '30%' : '90%',
      data:  row
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isLoading++;
        this.projectService.delete(row.id)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully deleted!');
              this.fetchProjects();
            },
            err => console.log(err)
          );
      }
    });
  }

}
