import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../_shared/models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GlobalEventsService} from '../../_shared/services/global-events.service';

@Component({
  selector:    'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls:   ['./user-editor.component.css']
})
export class UserEditorComponent implements OnInit {
  public editorType: string;
  public isLoading = 0;
  public user: User;
  public authUser: User;
  public form: FormGroup;

  constructor(public toastrService: ToastrService,
              public fb: FormBuilder,
              private globalEventsService: GlobalEventsService,
              public dialogRef: MatDialogRef<UserEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editorType = data.editorType;
    this.user = data.user;
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(
      user => {
        this.authUser = user;
      }
    );
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      role_id:    [this.user ? this.user.role_id : null,  [Validators.required]],
      name:       [this.user ? this.user.name : null,     [Validators.required, Validators.minLength(3)]],
      email:      [this.user ? this.user.email : null,    [Validators.required, Validators.email]],
      password:   [null, this.editorType === 'create' ?   [Validators.required, Validators.minLength(6)] : []],
      pref_hours: [this.user ? this.user.pref_hours : null, []]
    });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  submitForm() {
    if (this.form.invalid) {
      this.toastrService.error('Please fill all fields!');
      return;
    }

    const formData = this.form.value;

    this.dialogRef.close(formData);
  }
}
