import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {LayoutModule} from './_layout/layout.module';
import {GlobalEventsService} from './_shared/services/global-events.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HeaderInterceptor} from './_shared/interceptors/HeaderInterceptor';
import {AuthModule} from './auth/auth.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {ToastrModule} from 'ngx-toastr';
import {SettingsModule} from './settings/settings.module';
import {UserModule} from './user/user.module';
import {ProjectModule} from './project/project.module';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    ToastrModule.forRoot(),
    QuillModule.forRoot(),

    LayoutModule,
    AuthModule,
    DashboardModule,
    SettingsModule,
    UserModule,
    ProjectModule,
  ],
  providers: [
    GlobalEventsService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
