import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../_shared/models/User';
import {Project} from '../../../_shared/models/Project';
import {ConfirmDialogComponent} from '../../../confirm-dialog/confirm-dialog.component';
import {filter, finalize} from 'rxjs/operators';
import {WorkHistoryService} from '../work-history.service';
import {WorkHistoryEditorComponent} from '../work-history-editor/work-history-editor.component';
import {GlobalEventsService} from '../../../_shared/services/global-events.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-work-history-preview',
  templateUrl: './work-history-preview.component.html',
  styleUrls: ['./work-history-preview.component.css']
})
export class WorkHistoryPreviewComponent implements OnInit {
  public user: User;
  public projects: Project[];
  public users: User[];
  public isLoading = 0;
  public form: FormGroup;
  public record: any;
  public editorType: string;
  public authUser: User;
  public belongsToThisProject: boolean;

  constructor(public toastrService: ToastrService,
              public dialogRef: MatDialogRef<WorkHistoryPreviewComponent>,
              public fb: FormBuilder,
              private dialog: MatDialog,
              private workHistoryService: WorkHistoryService,
              public globalEventsService: GlobalEventsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private datePipe: DatePipe) {
    this.editorType = data.editorType;
    this.record     = data.record;
    this.users      = data.users;
    this.user       = data.user ? data.user : data.record.user;
    this.projects   = data.projects;
  }

  ngOnInit(): void {
    this.populateForm();
    this.authUser = this.globalEventsService.getAuthUser();
    this.belongsToThisProject = this.users.some(oneUser => oneUser.id === this.user.id);
  }

  populateForm() {
    this.form = this.fb.group({
      user:        [this.user ?  this.user.name : null, []],
      project_id:  [this.record ? this.record.project_id : null, []],
      project_name: [this.record ? this.record.project.name : null, []],
      date:        [this.record ? this.datePipe.transform(this.record.date, 'dd/MM/yyyy') : new Date(),
        [Validators.required]],
      hours:       [this.record ? this.record.hours : null, [Validators.required, Validators.min(0)]],
      description: [this.record ? this.record.description : null, []]
    });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  onEditClick(){
    const dialogRef = this.dialog.open(WorkHistoryEditorComponent, {
      width: '90%',
      autoFocus: true,
      disableClose: true,
      data:  {
        editorType: 'edit',
        record: this.record,
        projects: this.projects,
        users: this.users,
        user: this.user,
      },
      panelClass: 'custom-modalbox'
    });

    // Enable ESC close
    dialogRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => dialogRef.close());

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.workHistoryService.update(this.record.id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully edited!');
              this.dialogRef.close(true);
            },
            err => console.log(err)
          );
      }
    });
  }

  onDeleteClick(){
    const row = this.record;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data:  row,
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isLoading++;
        this.workHistoryService.delete(row.id)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully deleted!');
              this.dialogRef.close(true);
            },
            err => console.log(err)
          );
      }
    });
  }
}
