<div class="card">
  <div class="card-header">
    <div class="card-title text-center mb-0">
      <h5 class="mb-0">{{ editorType === 'create' ? 'Add new record' : 'Update record' }}</h5>
    </div>
  </div>

  <div class="card-body">

    <div *ngIf="isLoading" class="row">
      <div class="col-12">
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>

    <form *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="text" matInput placeholder="Project name" formControlName="name">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Description</mat-label>
            <input type="text" matInput placeholder="Description" formControlName="description">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Client</mat-label>
            <input type="text" matInput placeholder="Client" formControlName="client">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Note</mat-label>
            <input type="text" matInput placeholder="Note" formControlName="note">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Manager</mat-label>
            <mat-select formControlName="manager_id" placeholder="Select manager">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let manager of managers" [value]="manager.id">{{ manager.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="col-12 text-right">
        <button class="btn btn-danger mr-2" type="button" (click)="onCloseClick()">Close</button>
        <button class="btn btn-primary" type="submit">{{ editorType === 'create' ? 'Create' : 'Update' }}</button>
      </div>
    </form>

  </div>
</div>
