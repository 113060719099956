<div class="card" style="margin-bottom: 10px">
  <div *ngIf="isLoading" class="col-12">
    <div>
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <div class="card-header d-flex justify-content-start">
      <span class="btn mr-2 tab-option" (click)="loadComponent('user')"
            [ngClass]="{'chosen':userWorkLogComponent}">{{authUser.name}}</span>
      <span class="btn mr-2 tab-option" *ngIf="authUser.role_id === 1" (click)="loadComponent('All data')"
            [ngClass]="{'chosen':allProjectsWorkLogComponent}">All data</span>
      <div *ngIf="authUser.role_id === 1" class="scrollable">
        <span *ngFor="let project of projects" class="btn tab-option" (click)="loadComponent('project', project)"
              [ngClass]="{'chosen': (singleProjectWorkLogComponent && project === chosenProject)}">{{project.name}}</span>
      </div>

      <div *ngIf="authUser.role_id === 2" class="scrollable">
        <span *ngFor="let project of projects" class="btn tab-option" (click)="loadComponent('project', project)"
              [ngClass]="{'chosen': (singleProjectWorkLogComponent && project === chosenProject)}">{{project.name}}</span>
      </div>

    </div>

    <div *ngIf="isLoadingProjectWorkLog" class="col-12">
      <div>
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>
    <app-work-history *ngIf="userWorkLogComponent" [user]="authUser"></app-work-history>
    <app-all-work-history *ngIf="allProjectsWorkLogComponent"></app-all-work-history>
    <app-project-work-history *ngIf="singleProjectWorkLogComponent" [user]="authUser" [project]="chosenProject"></app-project-work-history>
  </div>
</div>

<!--<mat-tab-group>-->
<!--  <ng-container *ngIf="authUser.role_id === 1">-->
<!--    <mat-tab label="All users">-->
<!--      <ng-template matTabContent>-->
<!--        <app-all-work-history [user]="authUser"></app-all-work-history>-->
<!--      </ng-template>-->
<!--    </mat-tab>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="authUser.role_id === 2">-->
<!--    <mat-tab *ngFor="let project of projects" label="{{project.name}}">-->
<!--      <ng-template matTabContent>-->
<!--        <app-project-work-history [project]="project"></app-project-work-history>-->
<!--      </ng-template>-->
<!--    </mat-tab>-->
<!--  </ng-container>-->

<!--  <mat-tab label="{{authUser.name}}">-->
<!--    <ng-template matTabContent>-->
<!--      <app-work-history [user]="authUser"></app-work-history>-->
<!--    </ng-template>-->
<!--  </mat-tab>-->
<!--</mat-tab-group>-->
