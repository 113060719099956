import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import {GlobalEventsService} from '../services/global-events.service';

@Injectable()
export class GuestGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(public router: Router,
              private globalEventsService: GlobalEventsService) {
  }

  public canActivate(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkGuestStatus();
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot,
                          state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkGuestStatus();
  }

  private checkGuestStatus(): Observable<boolean> {
    return this.globalEventsService.authStatus$.pipe(filter(result => (result === true || result === false)), map(result => {
        return !result;
      }), take(1),
      tap(result => {
        if (!result) {
          this.router.navigate(['/dashboard']);
        }
      }));
  }

}
