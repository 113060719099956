import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../_shared/models/User';
import {Project} from '../../../_shared/models/Project';
import {GlobalEventsService} from '../../../_shared/services/global-events.service';

@Component({
  selector: 'app-work-history-editor',
  templateUrl: './work-history-editor.component.html'
})
export class WorkHistoryEditorComponent implements OnInit {
  public user: User;
  public projects: Project[];
  public users: User[];
  public isLoading = 0;
  public form: FormGroup;
  public editorType: string;
  public formType: string;
  private record: any;
  private date;
  public authUser: User;

  constructor(public toastrService: ToastrService,
              public globalEventsService: GlobalEventsService,
              public dialogRef: MatDialogRef<WorkHistoryEditorComponent>,
              public fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.editorType = data.editorType;
    this.formType = data.formType;
    this.record = data.record;
    this.user = data.user;
    this.projects = data.projects;
    this.users = data.users;
    this.date = data.date;
  }

  ngOnInit(): void {
    this.authUser = this.globalEventsService.getAuthUser();
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      user_id: [this.record ? this.record.user_id : null, [this.authUser.role_id === 1 && !this.formType ? Validators.required : Validators.nullValidator]],
      project_id: [this.record ? this.record.project_id : (this.projects.length !== 0 ? this.projects[0].id : null), [Validators.required]],
      date: [this.record ? this.record.date : new Date(this.date), [Validators.required]],
      hours: [this.record ? this.record.hours : (this.user ? this.user.pref_hours : 8.00), [Validators.required, Validators.min(0.00), Validators.max(99.99)]],
      description: [this.record ? this.record.description : null, [Validators.maxLength(65535)]]
    });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  submitForm() {
    if (this.form.invalid) {
      const errors = this.findInvalidControls();
      errors.map(error => this.toastrService.error(error));
    } else {
      const formData = this.form.value;
      formData.date = moment(formData.date).format('YYYY-MM-DD');
      this.dialogRef.close(formData);
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        switch (name) {
          case 'user_id':
            invalid.push('User ID is required');
            break;
          case 'project_id':
            invalid.push('Project ID is required');
            break;
          case 'date':
            invalid.push('Date is required');
            break;
          case 'hours':
            invalid.push('Hours is required and must be in range from 0 to 99.99');
            break;
          case 'description':
            invalid.push('Description can not be longer than 65 535 characters');
            break;
        }
      }
    }
    return invalid;
  }
}
