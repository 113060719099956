import {Component, Inject, OnInit} from '@angular/core';
import {Project} from '../../_shared/models/Project';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../_shared/models/User';
import {UserService} from "../../user/user.service";
import {finalize} from "rxjs/operators";

@Component({
  selector:    'app-project-editor',
  templateUrl: './project-editor.component.html'
})
export class ProjectEditorComponent implements OnInit {
  public editorType: string;
  public isLoading = 0;
  public project: Project;
  public form: FormGroup;
  public managers: User[] = [];

  constructor(public toastrService: ToastrService,
              public userService: UserService,
              public fb: FormBuilder,
              public dialogRef: MatDialogRef<ProjectEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editorType = data.editorType;
    this.project    = data.project;
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchManagers();
  }

  buildForm() {
    this.form = this.fb.group({
      name:        [this.project ? this.project.name : null, [Validators.required, Validators.minLength(3)]],
      description: [this.project ? this.project.description : null, []],
      client:      [this.project ? this.project.client : null, []],
      note:        [this.project ? this.project.note : null, []],
      manager_id:     [this.project ? this.project.manager_id : null, []]
    });
  }

  private fetchManagers() {
    this.isLoading++;
    this.userService.index({role_id: 2})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => this.managers = res.data,
        err => console.log(err)
      );
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  submitForm() {
    if (this.form.invalid) {
      this.toastrService.error('Please fill all fields!');
      return;
    }

    const formData = this.form.value;

    this.dialogRef.close(formData);
  }
}
