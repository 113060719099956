import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import {Observable} from 'rxjs';
import {GlobalEventsService} from '../services/global-events.service';
import {filter, map, take, tap} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private globalEventsService: GlobalEventsService,
              public router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }

  private checkLogin(): Observable<boolean> | Promise<boolean> | boolean {
    return this.globalEventsService.authStatus$
      .pipe(
        filter(result => (result === true || result === false)),  // If null, ignore it
        // tap(res => {
        //   console.log(res);
        //   if (!res) {
        //     this.globalEventsService.setAuthUser(null);
        //     this.router.navigate(['/login']);
        //   }
        // })
      );
  }

}
