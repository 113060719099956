<div class="card">
  <div class="card-header">
    <div class="card-title text-center mb-0">
      <h5 class="mb-0">Add/remove users</h5>
    </div>
  </div>

  <div class="card-body">

    <div *ngIf="isLoading" class="row">
      <div class="col-12">
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>

    <form *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput readonly formControlName="project_name">
          </mat-form-field>
        </div>
      </div>

      <div>
        <section class="example-section">
          <span class="example-list-section">
            <mat-checkbox class="example-margin"
                          [checked]="allSelected"
                          [indeterminate]="someSelected()"
                          (change)="setAll($event.checked)">
              {{input.name}}
            </mat-checkbox>
          </span>
                  <span class="example-list-section">
            <ul>
              <li *ngFor="let worker of input.workers" style="list-style-type: none">
                <mat-checkbox [(ngModel)]="worker.selected"
                              [ngModelOptions]="{standalone: true}"
                              [color]="worker.color"
                              (ngModelChange)="updateAllComplete()">
                  {{worker.name}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
      </div>

      <div class="col-12 text-right">
        <button class="btn btn-dark mr-2" type="button" (click)="onCloseClick()">Close</button>
        <button class="btn btn-primary" type="submit">Add/Remove</button>
      </div>
    </form>

  </div>
</div>
