import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {GlobalEventsService} from '../services/global-events.service';
import {User} from '../models/User';
import {filter, map, take, tap} from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
  private authUser: User;

  constructor(private globalEventsService: GlobalEventsService,
              public router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(route);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(childRoute);
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(route);
  }

  private check(route): Observable<any> | Promise<boolean> | boolean {
    return this.globalEventsService.authUser$.pipe(
      filter(result => (result !== null)),  // If null, ignore it
      map(res => this.checkPermission(res, route), take(1))
    );
  }

  private checkPermission(user, route) {
    if (!user) {
      return false;
    }

    // If admin - allow all
    if (user.role_id === 1) {
      return true;
    }

    if (user.role_id === 2) {
      if (!route.data.allowManager) {
        this.router.navigate(['/dashboard']);
      }
      return route.data.allowManager;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
