import {Role} from './Role';
import {WorkLog} from './WorkLog';

export class User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  role: Role;
  role_id: number;

  work_logs: WorkLog[] = [];

  updated_at?: string;
  public pref_hours: number;
}
