import {NgModule} from '@angular/core';
import {UserListComponent} from './user-list/user-list.component';
import {SharedModule} from '../_shared/shared.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {UserEditorComponent} from './user-editor/user-editor.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { UserDetailComponent } from './user-detail/user-detail.component';
import {WorkHistoryModule} from '../dashboard/work-history/work-history.module';

@NgModule({
  declarations: [
    UserListComponent,
    UserEditorComponent,
    UserDetailComponent
  ],
  imports: [
    SharedModule,

    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,

    WorkHistoryModule
  ]
})
export class UserModule {
}
