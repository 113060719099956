<div class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
  <div class="container">
    <a routerLink="/" class="navbar-brand">
      <img src="assets/softelm.svg" alt="Softelm" style="height: 25px; margin-top: -5px"/>
    </a>

    <button class="navbar-toggler" type="button"
            data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <ng-container *ngIf="authUser">
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">

          <li class="nav-item">
            <a class="nav-link" routerLink="/">Dashboard</a>
          </li>

          <li *ngIf="authUser.role_id === 1" class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/users">Users</a>
          </li>

          <li *ngIf="authUser.role_id === 1 || authUser.role_id === 2" class="nav-item">
            <a class="nav-link" routerLink="/projects" routerLinkActive="active">Projects</a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="download">{{ authUser.name }} <span class="caret"></span></a>
            <div class="dropdown-menu">
              <a routerLink="/settings" style="cursor: pointer" class="dropdown-item" >Settings</a>
              <div class="dropdown-divider"></div>
              <a style="cursor: pointer" class="dropdown-item" (click)="logout()">Logout</a>
            </div>
          </li>

        </ul>
      </div>
    </ng-container>

  </div>
</div>
