import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import {AuthService} from './auth.service';
import {SharedModule} from '../_shared/shared.module';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule { }
