import {NgModule} from '@angular/core';
import {ProjectListComponent} from './project-list/project-list.component';
import {SharedModule} from '../_shared/shared.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ProjectEditorComponent} from './project-editor/project-editor.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectPopulateComponent } from './project-populate/project-populate.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectEditorComponent,
    ProjectDetailComponent,
    ProjectPopulateComponent
  ],
    imports: [
        SharedModule,

        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatCheckboxModule
    ]
})
export class ProjectModule {
}
