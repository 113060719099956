import {Component, OnInit} from '@angular/core';
import {User} from '../../_shared/models/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LaravelResponse} from '../../_shared/contracts/laravel-response.interface';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';

@Component({
  selector:    'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  public user: User;
  public form: FormGroup;
  public serverResponse: LaravelResponse;
  public isLoading           = 0;

  constructor(public authService: AuthService,
              private routerService: Router,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.getCSRFToken();
    this.buildForm();
  }

  private getCSRFToken() {
    this.authService.getCSRFCookie()
      .subscribe(
        r => {},
        e => console.error(e)
      );
  }

  buildForm() {
    this.form = this.fb.group({
      name:                  ['Administrator Admin', [Validators.required, Validators.minLength(3)]],
      email:                 ['admin@admin.com', [Validators.required, Validators.email]],
      password:              ['secret', Validators.required],
      password_confirmation: ['secret', Validators.required]
    });
  }

  public submitForm() {
    this.isLoading++;
    this.authService.register(this.form.value)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.routerService.navigate(['/dashboard']);
        },
        error => {
          console.log(error);
          this.serverResponse = error;
        },
      );
  }

}
