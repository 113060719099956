import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavComponent} from './nav/nav.component';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from './header/header.component';


@NgModule({
  declarations: [
    NavComponent,
    HeaderComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
    ],
  exports:      [
    NavComponent,
    HeaderComponent,
  ],
})
export class LayoutModule {
}
