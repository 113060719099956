<div class="card" id="card">
  <div class="card-header">
    <div class="card-title mb-0">
      <div class="row">
        <div class="col-12">
          <h3>Project list
          <button *ngIf="authUser.role_id === 1" class="btn btn-primary ml-auto float-right" (click)="addProject()">Add Project</button>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">

    <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" style="width: 100%" class="col-20">
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

      <ng-container matColumnDef="name" class="col-3">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 5px; word-break: break-word">Name</th>
        <td mat-cell *matCellDef="let row" style="padding-left: 5px; word-break: break-word">{{ row.name }}</td>
      </ng-container>

      <ng-container matColumnDef="description" class="col-4">
        <th mat-header-cell *matHeaderCellDef style="word-break: break-word">Description</th>
        <td mat-cell *matCellDef="let row" style="word-break: break-word"> {{row.description}} </td>
      </ng-container>

      <ng-container matColumnDef="client" class="col-3">
        <th mat-header-cell *matHeaderCellDef style="word-break: break-word">Client</th>
        <td mat-cell *matCellDef="let row" style="word-break: break-word"> {{row.client}} </td>
      </ng-container>

      <ng-container matColumnDef="note" class="col-4">
        <th mat-header-cell *matHeaderCellDef style="word-break: break-word">Note</th>
        <td mat-cell *matCellDef="let row" style="word-break: break-word"> {{row.note}} </td>
      </ng-container>

      <ng-container matColumnDef="manager" class="col-3">
        <th mat-header-cell *matHeaderCellDef style="word-break: break-word">Manager</th>
        <td mat-cell *matCellDef="let row" style="word-break: break-word"> {{row.manager ? row.manager.name : 'none'}} </td>
      </ng-container>

      <ng-container matColumnDef="actions" class="col-3">
        <th mat-header-cell *matHeaderCellDef style="padding-right: 5px;"></th>
        <td mat-cell *matCellDef="let row" class="text-right" style="padding-right: 5px;">
          <button *ngIf="authUser.role_id === 1" class="btn btn-sm btn-primary m-1" (click)="addUserToProject(row)">Add user</button>
          <button *ngIf="authUser.role_id === 1" class="btn btn-sm btn-warning m-1" (click)="editProject(row)">Edit</button>
          <button *ngIf="authUser.role_id === 1" class="btn btn-sm btn-danger m-1" (click)="deleteProject(row)">Delete</button>
        </td>
      </ng-container>
    </table>

    <div *ngIf="isLoading" class="row my-4">
      <div class="col-12">
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>

    <mat-paginator
      [length]="paginatorConfig.length"
      [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="paginatorChange($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
