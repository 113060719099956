import { NgModule } from '@angular/core';
import {SharedModule} from '../../_shared/shared.module';
import {WorkHistoryComponent} from './work-history.component';
import {WorkHistoryEditorComponent} from './work-history-editor/work-history-editor.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {WorkHistoryService} from './work-history.service';
import {MatSelectModule} from '@angular/material/select';
import {QuillModule} from 'ngx-quill';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { WorkHistoryPreviewComponent } from './work-history-preview/work-history-preview.component';
import {DatePipe} from '@angular/common';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    WorkHistoryComponent,
    WorkHistoryEditorComponent,
    WorkHistoryPreviewComponent
  ],
  imports: [
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectModule,
    QuillModule,
    FullCalendarModule,
  ],
  exports: [
    WorkHistoryComponent,
    WorkHistoryEditorComponent
  ],

  providers:    [
    WorkHistoryService,
    DatePipe
  ]
})
export class WorkHistoryModule { }
