import {AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {WorkLog} from '../../_shared/models/WorkLog';
import {filter, finalize} from 'rxjs/operators';
import {WorkHistoryService} from '../work-history/work-history.service';
import {saveAs} from 'file-saver/src/FileSaver';
import {Project} from '../../_shared/models/Project';
import {CalendarOptions, EventClickArg} from '@fullcalendar/angular';
import {MatDialog} from '@angular/material/dialog';
import {WorkHistoryPreviewComponent} from '../work-history/work-history-preview/work-history-preview.component';
import {WorkHistoryEditorComponent} from '../work-history/work-history-editor/work-history-editor.component';
import {ProjectService} from '../../project/project.service';
import {UserService} from '../../user/user.service';
import {ToastrService} from 'ngx-toastr';
import {User} from '../../_shared/models/User';

@Component({
  selector: 'app-project-work-history',
  templateUrl: './project-work-history.component.html',
  styleUrls: ['./project-work-history.component.css']
})
export class ProjectWorkHistoryComponent implements OnInit, AfterContentChecked, AfterContentInit {

  @Input() user: User;
  @Input() project: Project;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    contentHeight: 'auto',
    firstDay: 1,
    weekends: true,
    editable: false,
    eventDurationEditable: false,
    selectMirror: true,
    selectable: true,
    dayMaxEvents: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth dayGridWeek dayGridDay'
    },
    events: [],
    eventClick: this.handleEventClick.bind(this),
    dateClick: this.handleDateClick.bind(this),
    datesSet: this.handleDateRangeChange.bind(this),
    navLinks: true,
    showNonCurrentDates: false,
    windowResize: this.handleWindowResize.bind(this)
  };

  public dataSource: MatTableDataSource<WorkLog>;
  public workLogs: WorkLog[] = [];
  public projects: Project[] = [];
  public users: User[] = [];
  public filterForm: FormGroup;
  public isLoading = 0;
  public isLoadingProjects = 0;
  public isLoadingUsers = 0;
  public totalWorkHours = 0;
  public workHoursPerDay = [];
  public projectsData = {};

  constructor(private workHistoryService: WorkHistoryService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private projectService: ProjectService,
              private userService: UserService,
              private toastrService: ToastrService,
              private cdref: ChangeDetectorRef
  ) {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngAfterContentInit() {
    this.handleWindowResize();
  }

  ngOnInit(): void {
    this.fetchUsers();
  }

  private handleWindowResize() {
    if (document.getElementById('calendarDiv').offsetWidth < 768) {
      this.calendarOptions.navLinks = false;
      this.calendarOptions.headerToolbar = {
        left: 'prev',
        center: 'title',
        right: 'next'
      };
    } else {
      this.calendarOptions.navLinks = true;
      this.calendarOptions.headerToolbar = {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth dayGridWeek dayGridDay'
      };
    }
  }

  private handleDateRangeChange(info) {
    const start = moment(info.startStr).format('YYYY-MM-DD');
    const end = moment(info.endStr).format('YYYY-MM-DD');
    this.filterForm = this.fb.group({
      date_from: [start],
      date_to: [end],
    });
    this.fetchWorkLog();
  }

  private handleDateClick(clickInfo) {
    if (this.user.role_id === 1) {
      this.addWorkLog(clickInfo.dateStr);
    }
  }

  private handleEventClick(clickInfo: EventClickArg) {
    const event = clickInfo.event._def.extendedProps.all;
    const dialogRef = this.dialog.open(WorkHistoryPreviewComponent, {
      width: '90%',
      autoFocus: true,
      disableClose: true,
      data: {
        record: event,
        projects: [this.project],
        users: this.users,
        editorType: this.user.role_id === 1 || (this.user.role_id === 2 && this.user.id === event.user.id) ? 'editPreview' : 'preview',
      },
      panelClass: 'custom-modalbox'
    });

    // Enable ESC close
    dialogRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => dialogRef.close());

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchWorkLog();
      }
    });
  }

  private fetchWorkLog() {
    this.totalWorkHours = 0;
    const filter = this.filterForm.value;
    this.dataSource = new MatTableDataSource<WorkLog>([]);
    this.isLoading++;
    const data: any = {
      date_from: filter.date_from,
      date_to: filter.date_to
    };
    data.projectId = this.project.id;

    this.workHistoryService.indexProjectWorkLog(data)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        async res => {
          await this.acceptResponseData(res);
          this.populateCalendarEventsWithNewData();
        },
        err => console.log(err)
      );
  }

  private acceptResponseData(res) {
    this.totalWorkHours = 0;
    this.totalWorkHours = res.data.hours.total;
    this.workHoursPerDay = res.data.hours.perDate;
    this.workLogs = res.data.work;
    this.dataSource = new MatTableDataSource<WorkLog>(this.workLogs);
  }

  private populateCalendarEventsWithNewData() {
    this.calendarOptions.events = this.workLogs.map(work => {
      const name = work.project ? work.project.name : 'Unknown';
      const username = work.user.name.split(' ').map((element, index) => index === 0 ? element : element[0]).join(' ');
      return {
        title: username + ' - ' + name + ' ' + work.hours + ' hrs',
        date: work.date,
        backgroundColor: this.getHoursFromDateHours(work) >= work.user.pref_hours ? '#b9dcc1' : '#ebb6bd',
        borderColor: 'rgba(13, 13, 13, 0.125)',
        textColor: '#111',
        desc: work.description,
        all: work
      };
    });
  }

  private fetchUsers() {
    this.isLoadingUsers++;
    this.userService.index({project_id: this.project.id, all: true})
      .pipe(finalize(() => this.isLoadingUsers--))
      .subscribe(
        res => {
          this.users = res.data;
        },
        err => console.log(err)
      );
  }

  public addWorkLog(clickedDate) {
    const dialogRef = this.dialog.open(WorkHistoryEditorComponent, {
      width: '90%',
      autoFocus: true,
      disableClose: true,
      data: {
        editorType: 'create',
        projects: [this.project],
        users: this.users,
        date: clickedDate
      },
      panelClass: 'custom-modalbox'
    });

    // Enable ESC close
    dialogRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => dialogRef.close());

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.workHistoryService.store(result.user_id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully added!');
              this.fetchWorkLog();
            },
            err => console.log(err)
          );
      }
    });
  }

  public getKeyFromWorkLog(workLog) {
    return moment(workLog.date).format('YYYY-MM-DD');
  }

  public getHoursFromDateHours(workLog) {
    let hours: number;
    hours = 0;
    this.workLogs.map(singleInput => {
      if (singleInput.date === this.getKeyFromWorkLog(workLog) && singleInput.user_id === workLog.user_id) {
        hours += Number(singleInput.hours);
      }
    });
    return hours;
  }

  public exportTable() {
    const exportObject = {};

    this.workLogs.map(e => {
      const key = moment(e.date).format('YYYYMMDD');
      exportObject[key] = exportObject[key] || {
        hours: 0,
        notes: [],
        date: e.date
      };

      exportObject[key].hours += e.hours;
      exportObject[key].notes.push(e.description);
    });


    let output = '<!DOCTYPE html>\n' +
      '<html lang="en">\n' +
      '  <head><title>Export</title>' +
      '    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" media="screen">\n' +
      '    <style>\n' +
      '      html, body {background-color: #ddd;}\n' +
      '      table {background-color: #fff;}\n' +
      '    </style>' +
      '  </head>' +
      '  <body><div class="container p-5">';

    output += '<table class="table">\n' +
      '  <thead>\n' +
      '    <tr>\n' +
      '      <th scope="col">Date</th>\n' +
      '      <th scope="col">Total time</th>\n' +
      '      <th scope="col">Notes</th>\n' +
      '    </tr>\n' +
      '  </thead>\n' +
      '  <tbody>\n';


    Object.keys(exportObject).map(key => {
      const element = exportObject[key];

      output += '    <tr>\n' +
        '      <th scope="row">' + element.date + '</th>\n' +
        '      <td>' + element.hours + ' h</td>\n';

      output += '<td><ul>';

      element.notes.map(note => output += '<li>' + (note || '') + '</li>');

      output += '</ul></td>\n</tr>';
    });

    output += '  </tbody>' +
      '</table>';

    output += '</div></body></html>';

    this.downloadFile(output);
  }

  public downloadFile(content) {
    const blob = new Blob([content], {type: 'html'});
    const url = window.URL.createObjectURL(blob);
    saveAs(blob, 'export.html');
    window.open(url);
  }
}
