import {Component, OnInit} from '@angular/core';
import {User} from '../../_shared/models/User';
import {finalize} from 'rxjs/operators';
import {ProjectService} from '../project.service';
import {ActivatedRoute, Route} from '@angular/router';
import {ProjectEditorComponent} from '../project-editor/project-editor.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MatTableDataSource} from '@angular/material/table';
import {WorkLog} from '../../_shared/models/WorkLog';

@Component({
  selector:    'app-project-detail',
  templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent implements OnInit {
  public isLoading = 0;
  public user: User;
  public userId;
  public columnsToDisplay    = ['date', 'hours', 'description', 'actions'];
  public dataSource: MatTableDataSource<WorkLog>;


  constructor(public projectService: ProjectService,
              private route: ActivatedRoute,
              public toastrService: ToastrService,
              public dialog: MatDialog) {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.fetchUser();
  }

  ngOnInit(): void {

  }

  private fetchUser() {
    this.isLoading++;
    this.projectService.show(this.userId)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.user = res.data;

        },
        err => console.log(err)
      );
  }

  public editUser(row: any) {
    const dialogRef = this.dialog.open(ProjectEditorComponent, {
      width: '40%',
      data:  {
        user: row,
        editorType: 'edit'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading++;
        this.projectService.update(row.id, result)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            res => {
              this.toastrService.success('Successfully edited!');
              this.fetchUser();
            },
            err => console.log(err)
          );
      }
    });
  }
}
