<div class="card">
  <div class="card-header">
    <div class="card-title text-center mb-0">
      <h5 class="mb-0">{{ editorType === 'create' ? 'Add new record' : 'Update record' }}</h5>
    </div>
  </div>

  <div class="card-body">

    <div *ngIf="isLoading" class="row">
      <div class="col-12">
        <mat-spinner style="margin: auto"></mat-spinner>
      </div>
    </div>

    <form *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div *ngIf="authUser.role_id === 1 && !formType" class="col-12">
              <mat-form-field>
                <mat-label>User</mat-label>
                <mat-select formControlName="user_id" placeholder="Select user">
                  <mat-option *ngFor="let user of users" [value]="user.id">{{ user.name }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-12">
              <mat-form-field>
                <mat-label>Project</mat-label>
                <mat-select formControlName="project_id" placeholder="Select project">
                  <mat-option *ngFor="let project of projects" [value]="project.id">{{ project.name }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-lg-6">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker1" readonly formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-12 col-sm-6 col-lg-6">
          <mat-form-field>
            <mat-label>Hours</mat-label>
            <input matInput placeholder="Ex. 8" formControlName="hours">
          </mat-form-field>
        </div>

        <div class="col-12" style="height: 100%; padding-bottom: 1.25em;">
          <mat-label>Description</mat-label>
          <quill-editor #editor
                        placeholder="Ex. Worked on XY project..."
                        formControlName="description"></quill-editor>
        </div>

        <div class="col-12 d-flex justify-content-end">
          <div>
            <button type="button" class="btn btn-dark mr-2" (click)="onCloseClick()">Close</button>
            <button type="submit" class="btn btn-primary">{{ editorType === 'create' ? 'Create' : 'Update' }}</button>
          </div>
        </div>
      </div>

    </form>

  </div>
</div>
